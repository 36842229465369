import DatePicker from 'react-datepicker';
import { Controller, useForm } from 'react-hook-form';
import languageData from '../../Utils/meta/languages.json';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import {
  getLanguageOptions,
  getOneLanguageObject,
  getOneLanguageObjectArray,
  getOneLanguageOption,
} from '../../Utils/languages';
import { useEffect, useState } from 'react';
import styles from '../../Utils/meta/styles.json';
import ArchiveQuestionnaire from './ArchiveQuestionnaire';
import AccessControl from '../AccessControl';

const QuestionnaireSettings = (props: any) => {
  const { license, onSubmit, data } = props;
  const {
    register,
    formState: { errors },
    reset,
    getValues,
    setValue,
    control,
  } = useForm<any>({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues: {},
    resolver: undefined,
    context: undefined,
    criteriaMode: 'firstError',
    shouldFocusError: true,
    shouldUnregister: false,
    delayError: undefined,
  });

  const animatedComponents = makeAnimated();
  const languages = getLanguageOptions(languageData);

  const [defaultLanguageOptions, setDefaultLanguageOptions] = useState<any[]>(
    []
  );

  const isIncluded = (array: any[], key: string, value: any): boolean => {
    return array.filter((item: any) => item[key] === value[key]).length !== 0;
  };

  const handleSupportedLanguagesOnChange = (supportedLanguageOptions: any) => {
    const { defaultLanguage } = getValues();

    setDefaultLanguageOptions(supportedLanguageOptions);

    if (!defaultLanguage) {
      setValue('defaultLanguage', supportedLanguageOptions?.[0]);
    } else if (
      !isIncluded(supportedLanguageOptions, 'value', defaultLanguage) &&
      supportedLanguageOptions.length
    ) {
      setValue('defaultLanguage', supportedLanguageOptions?.[0]);
    } else if (
      !isIncluded(supportedLanguageOptions, 'value', defaultLanguage) &&
      supportedLanguageOptions.length === 0
    ) {
      setValue('defaultLanguage', '');
    }
  };

  useEffect(() => {
    if (data) {
      setDefaultLanguageOptions(getLanguageOptions(data.supportedLanguages));
      const variables = {
        ...data,
        activeTill: new Date(Number(data.activeTill)),
        defaultLanguage: getOneLanguageOption(data.defaultLanguage),
        supportedLanguages: getLanguageOptions(data.supportedLanguages),
      };
      reset(variables);
    }
  }, [data, reset, setValue]);

  const handleSubmit = (event: any) => {
    event.preventDefault();
    const licenseId = license?._id;
    const {
      name,
      defaultLanguage: defaultLanguageObj,
      supportedLanguages: supportedLanguagesObj,
      activeFrom,
      activeTill,
    } = getValues();

    const defaultLanguage =
      defaultLanguageObj && getOneLanguageObject(defaultLanguageObj.value);
    const supportedLanguages =
      supportedLanguagesObj && getOneLanguageObjectArray(supportedLanguagesObj);

    const variables = {
      _id: data?._id,
      licenseId,
      name,
      defaultLanguage: defaultLanguage || {},
      supportedLanguages: supportedLanguages || [],
      activeFrom,
      activeTill,
      styles: styles.customTemplate,
    };

    onSubmit({ variables });
  };

  const handleArchiveQuestionnaireSelect = (event: any) => {
    event.preventDefault();
    props.toggleArchiveModal();
  };

  const handleArchiveQuestionnaire = () => {
    props.onArchive({
      variables: {
        _id: data._id,
      },
    });
  };

  return (
    <div className='questionnaire-settings-screen'>
      <div className='questionnaire-settings-container'>
        <div className='bg-wrapper'>
          <form onSubmit={handleSubmit}>
            <section>
              <div className='form-wrapper'>
                <div className='row'>
                  <div className='form-group col'>
                    <label>Questionnaire name</label>
                    <input
                      className='form-field'
                      type='text'
                      {...register('name', {
                        required: true,
                      })}
                    />
                    {errors.name && (
                      <span className='error-msg'>
                        Questionnaire name is required.
                      </span>
                    )}
                  </div>
                </div>
                <div className='row'>
                  <div className='form-group col'>
                    <div className='w-50'>
                      <label>Supported languages</label>

                      <Controller
                        control={control}
                        name='supportedLanguages'
                        rules={{ required: false }}
                        render={({ field: { onChange, value } }) => (
                          <Select
                            isMulti
                            options={languages}
                            value={value}
                            onChange={(val) => {
                              handleSupportedLanguagesOnChange(val);
                              return onChange(val);
                            }}
                            components={animatedComponents}
                          />
                        )}
                      />
                    </div>
                  </div>
                </div>
                <div className='row'>
                  <div className='form-group col'>
                    <div className='w-50'>
                      <label>Default language</label>
                      <Controller
                        control={control}
                        name='defaultLanguage'
                        rules={{ required: false }}
                        render={({ field: { onChange, value } }) => (
                          <Select
                            options={defaultLanguageOptions}
                            value={value}
                            onChange={onChange}
                          />
                        )}
                      />
                    </div>
                  </div>
                </div>
                <div className='row'>
                  <div className='form-group col'>
                    <div className='w-50'>
                      <label>Active till</label>
                      <Controller
                        control={control}
                        name='activeTill'
                        defaultValue={new Date()}
                        rules={{ required: true }}
                        render={({ field: { onChange, value } }) => (
                          <DatePicker selected={value} onChange={onChange} />
                        )}
                      />
                    </div>
                  </div>
                </div>
                <div className='row'>
                  <div className='form-group col'>
                    <div className='w-50'>
                      <AccessControl allowedPermissions={['update:survey']}>
                        <button className='submit' type='submit'>
                          Update
                        </button>
                      </AccessControl>
                    </div>
                  </div>
                </div>
                <div className='row horizontal-divider w-50 ml-1'>
                  <AccessControl allowedPermissions={['delete:survey']}>
                    <div className='danger-zone-wrapper'>
                      <div className='mt-3 title'>Danger Zone</div>
                      <div className='mt-3 red-zone'>
                        <div className='content-wrapper'>
                          <div className='mt-3 ml-3 label-txt'>
                            Archive Questionnaire
                          </div>
                          <div className='mt-3 ml-3'>
                            Once the questionnaire is archived and all published
                            versions of this questionnaire will no longer be
                            available.
                          </div>
                        </div>
                        <div className='ml-3 btn-wrapper'>
                          <button
                            className='archive ml-3'
                            onClick={handleArchiveQuestionnaireSelect}
                          >
                            Archive
                          </button>
                        </div>
                      </div>
                    </div>
                  </AccessControl>
                </div>
              </div>
            </section>
          </form>
        </div>
      </div>
      {props.openArchiveModal && (
        <ArchiveQuestionnaire
          modalIsOpen={props.openArchiveModal}
          toggleModal={props.toggleArchiveModal}
          modalTitle={'Archive Questionnaire'}
          modalData={data}
          error={props.archiveQuestionnaireError}
          onArchive={handleArchiveQuestionnaire}
          isLoading={props.archiveQuestionnaireLoading}
        />
      )}
    </div>
  );
};

export default QuestionnaireSettings;
