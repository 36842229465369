import React, { useEffect, useRef } from 'react';

import ToggleSwitch from '../../Basic/ToggleSwitch';
import { Controller, useFormContext } from 'react-hook-form';
import CodeEditor from '../../Common/CodeEditor';

const CustomFinalPage = (props: any) => {
  const htmlEditorRef = useRef<any>();

  const { data } = props;
  const { control, formState, register, reset } = useFormContext<any>();

  const { errors } = formState;

  const getQuestionsDataForForm = (questions: any, questionnaire: any) => {
    const formData: any = {};
    questionnaire?.supportedLanguages?.forEach((language: any) => {
      const question = questions?.questions?.find(
        (question: any) => question?.language?.code === language?.code
      );
      formData[language?.code] = {
        id: question?.text?.id || null,
        html: question?.text?.html?.html || question?.text?.html || null,
      };
    });
    return formData;
  };

  useEffect(() => {
    const questionsData = getQuestionsDataForForm(
      data.question,
      data.questionnaire
    );

    reset({
      ...questionsData,
      showNavigation: data.question?.attributes?.showNavigation,
      hideBackButton: data.question?.attributes?.hideBackButton,
    });
  }, [data.question, data.questionnaire, reset]);

  return (
    <>
      {!data.settings && (
        <div>
          <div className='inner-tabs'>
            <div>
              <div className='col pt-4'>
                <div className='form-group'>
                  <input
                    className={`${
                      errors[data.questionnaire?.defaultLanguage?.code]?.id
                        ? 'is-invalid'
                        : ''
                    } italic-input half-width-form-control `}
                    placeholder='Question label...'
                    type='text'
                    {...register(
                      `${data.questionnaire?.defaultLanguage?.code}.id`,
                      {
                        required: true,
                      }
                    )}
                    disabled={data.isLoading}
                  />
                </div>
                <div className='form-group'>
                  <div
                    id='html-editor'
                    className={`html-editor ${
                      errors[data.questionnaire?.defaultLanguage.code]?.html
                        ? 'is-invalid-editor'
                        : ''
                    }`}
                    ref={htmlEditorRef}
                  >
                    <Controller
                      control={control}
                      name={`${data.questionnaire?.defaultLanguage.code}.html`}
                      rules={{ required: true }}
                      render={({ field: { onChange, value } }) => (
                        <CodeEditor onChange={onChange} value={value} />
                      )}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {data.settings && (
        <div className='q-settings__wrapper'>
          <div className='form-group d-flex'>
            <Controller
              control={control}
              name='hideBackButton'
              rules={{ required: false }}
              render={({ field: { onChange, value = false } }) => (
                <ToggleSwitch
                  handleSwitch={onChange}
                  checked={value}
                  width={36}
                />
              )}
            />
            <label className='ml-2'>Hide back button</label>
          </div>
        </div>
      )}
    </>
  );
};

export default CustomFinalPage;
