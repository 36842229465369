import { html } from '@codemirror/lang-html';
import { Diagnostic, linter } from '@codemirror/lint';
import { syntaxTree } from '@codemirror/language';
import { keymap } from '@codemirror/view';
import { indentWithTab } from '@codemirror/commands';
import { zebraStripes } from './Utils/ZebraStripes';
import { autocompletion } from '@codemirror/autocomplete';
import CodeMirror from '@uiw/react-codemirror';
import { css } from '@codemirror/lang-css';

const CodeEditor = ({
  onChange,
  value,
  isCss,
}: {
  onChange: any;
  value: string;
  isCss?: boolean;
}) => {
  return (
    <CodeMirror
      value={value || '\n'.repeat(24)}
      extensions={[
        isCss ? css() : html(),
        keymap.of([indentWithTab]),
        autocompletion(),
        linter((view) => {
          let diagnostics: Diagnostic[] = [];
          syntaxTree(view.state)
            .cursor()
            .iterate((node) => {
              if (node.type.is('Script') && node.node?.parent) {
                diagnostics.push({
                  from: node.node.parent.from,
                  to: node.node.parent.to,
                  severity: 'error',
                  message: 'Scripts are FORBIDDEN',
                  actions: [
                    {
                      name: 'Remove',
                      apply(view, from, to) {
                        view.dispatch({ changes: { from, to } });
                      },
                    },
                  ],
                });
              }
            });
          return diagnostics;
        }),
        zebraStripes(),
      ]}
      onChange={onChange}
    />
  );
};

export default CodeEditor;
