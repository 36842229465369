import React, { Fragment, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV, faPlayCircle } from '@fortawesome/free-solid-svg-icons';
import { Popover } from 'react-tiny-popover';
import ReactHtmlParser from 'react-html-parser';

import { DeleteQuestionModal } from './DeleteQuestionModal';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import QuestionWrapper from './QuestionTypes/QuestionWrapper';
import ContentLoader from 'react-content-loader';
import AccessControl from '../AccessControl';
import Alert from '../Basic/AlertComponent';
import { connect } from 'react-redux';
import AddQuestionModal from './AddQuestionModal';

const QuestionsList = (props: any) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const [deleteModalIsOpen, setDeleteIsOpen] = useState(false);
  const [openPopover, setOpenPopover] = useState(false);
  const [questions, setQuestions] = useState<any[]>(props.questions || []);
  const [loadQuestions, setLoadQuestions] = useState(props.loadQuestions);

  useEffect(() => {
    setQuestions(props.questions || []);
    setLoadQuestions(props.loadQuestions);
  }, [props]);

  function toggleAddEdit() {
    setModalIsOpen(!modalIsOpen);
  }

  const handleAddQuestion = (data: any) => {
    toggleAddEdit();
    props.handleAddQuestion(data);
  };

  function toggleDelete() {
    setDeleteIsOpen(!deleteModalIsOpen);
  }

  const duplicateQuestion = (question: any) => {
    props.onDuplicate(question);
  };

  const handleDeleteQuestion = (question: any) => {
    props.handleDeleteQuestion({ variables: { questionId: question._id } });
    toggleDelete();
  };

  const handleScroll = (event: any) => {
    props.onScroll();
  };

  const checkPermissions = (
    permissions: string[],
    allowedPermissions: string[]
  ) => {
    return (
      permissions.filter(
        (permission: string) => allowedPermissions.indexOf(permission) !== -1
      ).length > 0
    );
  };

  const onDragEnd = (result: any) => {
    if (!result.destination) {
      return;
    }

    const newOrder: any = reorder(
      questions,
      result.source.index,
      result.destination.index
    );

    setQuestions(newOrder);
    props.onDragEnd({
      variables: {
        questions: newOrder.map((question: any, index: number) => {
          return {
            _id: question._id,
            order: index + 1,
          };
        }),
      },
    });
  };

  const reorder = (list: any, startIndex: number, endIndex: number) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  return (
    <div className='questions-screen'>
      <div className='d-flex'>
        <div className='q-list'>
          <div className='add-new'>
            <span>Questions</span>
            {loadQuestions ? (
              <AccessControl allowedPermissions={['add:question']}>
                <button className='add-new-btn' onClick={toggleAddEdit}>
                  +
                </button>
              </AccessControl>
            ) : (
              <div className='add-new-btn-skeleton'>
                <ContentLoader viewBox='0 0 380 70'>
                  <circle cx='30' cy='30' r='25' />
                </ContentLoader>
              </div>
            )}
          </div>
          <ul onScroll={handleScroll}>
            <DragDropContext onDragEnd={(result) => onDragEnd(result)}>
              <Droppable
                isDropDisabled={
                  !checkPermissions(props.userPermissions, [
                    'reorder:questions',
                  ])
                }
                droppableId='droppable'
              >
                {(provided) => (
                  <div ref={provided.innerRef} {...provided.droppableProps}>
                    {loadQuestions ? (
                      <AccessControl
                        allowedPermissions={['list:questions']}
                        renderNoAccess={() => (
                          <Alert
                            message={
                              'Unauthorized. You do not have permission to view questions'
                            }
                          />
                        )}
                      >
                        {questions.map((question: any, index: number) => {
                          return (
                            <Draggable
                              draggableId={question._id}
                              index={index}
                              key={question._id}
                            >
                              {(provided) => (
                                <AccessControl
                                  allowedPermissions={['view:question']}
                                  renderNoAccess={() => {
                                    return (
                                      <li
                                        key={question._id}
                                        className='disabled'
                                      >
                                        <div className='q-info'>
                                          <div className='q-info__number'>
                                            <span>{index + 1}</span>
                                          </div>
                                          <div className='q-info__question'>
                                            <div className='q-info__question--title'>
                                              {question.label}
                                            </div>
                                            <div className='q-info__question--type'>
                                              <FontAwesomeIcon
                                                className='mr-2'
                                                icon={faPlayCircle}
                                              />
                                              {question.type.replaceAll(
                                                '_',
                                                ' '
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                      </li>
                                    );
                                  }}
                                >
                                  <li
                                    ref={provided.innerRef}
                                    {...provided.dragHandleProps}
                                    {...provided.draggableProps}
                                    onClick={() =>
                                      props.handleQuestionSelect(question)
                                    }
                                    key={question._id}
                                    className={
                                      props.selectedQuestion?.pageId ===
                                      question.pageId
                                        ? 'q-active'
                                        : ''
                                    }
                                  >
                                    <div className='q-info'>
                                      <div className='q-info__number'>
                                        <span>{index + 1}</span>
                                      </div>
                                      <div className='q-info__question'>
                                        <div className='q-info__question--title'>
                                          {ReactHtmlParser(question.label)}
                                        </div>
                                        <div className='q-info__question--type'>
                                          <FontAwesomeIcon
                                            className='mr-2'
                                            icon={faPlayCircle}
                                          />
                                          {question.type.replaceAll('_', ' ')}
                                        </div>
                                      </div>
                                      <Popover
                                        isOpen={
                                          openPopover &&
                                          props.selectedQuestion?.pageId ===
                                            question.pageId
                                        }
                                        positions={['right']}
                                        onClickOutside={() =>
                                          setOpenPopover(false)
                                        }
                                        content={
                                          <div className='q-action-popover'>
                                            <AccessControl
                                              allowedPermissions={[
                                                'duplicate:question',
                                              ]}
                                            >
                                              <button
                                                onClick={() =>
                                                  duplicateQuestion(question)
                                                }
                                              >
                                                Duplicate
                                              </button>
                                            </AccessControl>
                                            <AccessControl
                                              allowedPermissions={[
                                                'delete:question',
                                              ]}
                                            >
                                              <button
                                                className='btn-delete'
                                                onClick={() => toggleDelete()}
                                              >
                                                Delete
                                              </button>
                                            </AccessControl>
                                          </div>
                                        }
                                      >
                                        <button
                                          className='btn-q-actions'
                                          onClick={() =>
                                            setOpenPopover(!openPopover)
                                          }
                                        >
                                          <FontAwesomeIcon icon={faEllipsisV} />
                                        </button>
                                      </Popover>
                                    </div>
                                  </li>
                                </AccessControl>
                              )}
                            </Draggable>
                          );
                        })}
                        {provided.placeholder}
                      </AccessControl>
                    ) : (
                      <Fragment>
                        <li>
                          <QuestionSkeleton />
                        </li>
                        <li>
                          <QuestionSkeleton />
                        </li>
                        <li>
                          <QuestionSkeleton />
                        </li>
                        <li>
                          <QuestionSkeleton />
                        </li>
                        <li>
                          <QuestionSkeleton />
                        </li>
                      </Fragment>
                    )}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </ul>
        </div>
        {props.selectedQuestion ? (
          <QuestionWrapper
            data={{
              questionnaire: props.questionnaire,
              question: props.selectedQuestion,
              questions,
              handleUpdateQuestion: props.handleUpdateQuestion,
              isLoading: props.isLoading,
            }}
            onQuestionFormEdit={props.onQuestionFormEdit}
          />
        ) : (
          <Fragment>
            <div className='question-wrapper'>
              <div className='q-content'></div>
              <div className='divider'></div>
              <div className='q-settings'></div>
            </div>
          </Fragment>
        )}
      </div>

      <AddQuestionModal
        modalIsOpen={modalIsOpen}
        toggleModal={toggleAddEdit}
        onSubmit={handleAddQuestion}
        modalTitle={'Add question'}
      />

      <DeleteQuestionModal
        modalIsOpen={deleteModalIsOpen}
        toggleModal={toggleDelete}
        question={props.selectedQuestion}
        modalTitle={'Delete Question'}
        onSubmit={handleDeleteQuestion}
      />
    </div>
  );
};

const QuestionSkeleton = () => {
  return (
    <ContentLoader viewBox='0 0 380 40'>
      <rect x='0' y='0' rx='5' ry='5' width='70' height='70' />
      <rect x='80' y='17' rx='4' ry='4' width='300' height='13' />
      <rect x='80' y='40' rx='3' ry='3' width='250' height='10' />
    </ContentLoader>
  );
};

const mapStateToProps = (state: any) => {
  return {
    userPermissions: state.user?.permissions || [],
  };
};

export default connect(mapStateToProps)(QuestionsList);
