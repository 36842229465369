import { Fragment, useState } from 'react';
import {
  faChevronRight,
  faInfoCircle,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMutation } from '@apollo/client';
import {
  PREVIEW_QUESTIONNAIRE,
  UPDATE_QUESTIONNAIRE_VERSION,
} from '../../Query/questionnaires.query';
import { UpdateQuestionnaireVersionModal } from './UpdateQuestionnaireVersionModal';
import { connect } from 'react-redux';
import { setQuestionnaireToStore } from '../../States/Actions/questionnaireAction';

import AccessControl from '../AccessControl';
import { QuestionnaireStatus, ToastTypes } from 'src/Utils/types';
import { useToast } from '../Basic/Toastify';

const QuestionnaireShare = (props: any) => {
  const { data } = props;
  const { showToast } = useToast();

  const [openVersionUpdateModal, setOpenVersionUpdateModal] = useState(false);

  const toggleVersionUpdateModal = () => {
    setOpenVersionUpdateModal(!openVersionUpdateModal);
  };

  const handleQuestionnairePreview = (event: any) => {
    event.preventDefault();
    previewQuestionnaire({
      variables: {
        questionnaireId: data.questionnaire._id,
      },
    }).then(({ data }) => {
      showToast('Questionnaire preview updated successfully');
      props.setQuestionnaireToStore(data.previewQuestionnaire);
    });
  };

  const [
    updateQuestionnaireVersion,
    {
      error: updateQuestionnaireVersionError,
      loading: updateQuestionnaireVersionLoading,
    },
  ] = useMutation(UPDATE_QUESTIONNAIRE_VERSION, {
    errorPolicy: 'none',
    onCompleted: (data) => {
      props.setQuestionnaireToStore(data.updateQuestionnaireVersion);
      toggleVersionUpdateModal();
      showToast('Questionnaire version updated successfully');
    },
    onError: (error) => {
      console.error(error);
      showToast(
        'Failed to update the version of the questionnaire',
        ToastTypes.ERROR
      );
    },
  });

  const [previewQuestionnaire, { loading: previewQuestionnaireLoading }] =
    useMutation(PREVIEW_QUESTIONNAIRE, {
      errorPolicy: 'none',
      onError: () => {
        showToast('Failed to preview the questionnaire', ToastTypes.ERROR);
      },
    });

  const handleCopyButtonClick = (link: string) => {
    navigator.clipboard.writeText(link);
    showToast('Preview link copied to clipboard');
  };

  const QuestionnairePublishActions = (props: any) => {
    return (
      <div className='share-actions'>
        <AccessControl allowedPermissions={['preview:survey']}>
          <button
            onClick={(event) => handleQuestionnairePreview(event)}
            disabled={
              previewQuestionnaireLoading || updateQuestionnaireVersionLoading
            }
          >
            Preview
            <span>
              <FontAwesomeIcon className='ml-3' icon={faChevronRight} />
            </span>
          </button>
        </AccessControl>
        {!data.isProductModule && (
          <AccessControl allowedPermissions={['publish:survey']}>
            <button
              onClick={(event) => {
                event.preventDefault();
                toggleVersionUpdateModal();
              }}
              disabled={
                previewQuestionnaireLoading || updateQuestionnaireVersionLoading
              }
            >
              {data.questionnaire.status === QuestionnaireStatus.CONCEPT
                ? 'Publish'
                : 'Republish'}
              <span>
                <FontAwesomeIcon className='ml-3' icon={faChevronRight} />
              </span>
            </button>
          </AccessControl>
        )}
      </div>
    );
  };

  const getQuestionnaireActionSegment = (status: string) => {
    return (
      <div>
        <div className='info'>
          <FontAwesomeIcon icon={faInfoCircle} className='icon' />
          Before being able to gather results from this questionnaire, you will
          need to publish it
        </div>
        <QuestionnairePublishActions status={data?.questionnaire?.status} />
      </div>
    );
  };

  return (
    <div className='questions-share-screen'>
      <div className='question-share-content'>
        <div className='d-flex fill-height'>
          <div className='share-content-actions'>
            <div className='share-content-action-info'>
              {getQuestionnaireActionSegment(data?.questionnaire?.status)}
            </div>
          </div>
          <div className='share-content-info'>
            {data.questionnaire?.isPreviewed && (
              <Fragment>
                <div className='share-content-info-header'>
                  Test this questionnaire
                </div>
                {data.questionnaire?.isPreviewed && (
                  <div>
                    <div className='share-content-info-header__subtitle'>
                      Preview questionnaire link
                    </div>
                    <div className='share-link'>
                      <input
                        type='text'
                        className='share-link-input'
                        disabled
                        value={data.questionnaire?.shareableLink?.previewLink}
                      ></input>

                      <button
                        onClick={() =>
                          handleCopyButtonClick(
                            data.questionnaire?.shareableLink?.previewLink
                          )
                        }
                        disabled={
                          previewQuestionnaireLoading ||
                          updateQuestionnaireVersionLoading
                        }
                      >
                        Copy link
                      </button>
                    </div>
                  </div>
                )}
                <div className='share-link-info'>
                  <FontAwesomeIcon icon={faInfoCircle} className='icon' />
                  This link can be used to preview the entire questionnaire or
                  test it with potential participants. If you wish to gather
                  actual results, you will need to add a sharing method
                </div>
              </Fragment>
            )}
          </div>
        </div>
      </div>
      {openVersionUpdateModal && (
        <UpdateQuestionnaireVersionModal
          modalIsOpen={openVersionUpdateModal}
          toggleModal={toggleVersionUpdateModal}
          modalTitle={
            data?.questionnaire?.status === QuestionnaireStatus.CONCEPT
              ? `Publish Questionnaire`
              : `Republish Questionnaire`
          }
          modalData={data.questionnaire}
          error={updateQuestionnaireVersionError}
          onSubmit={updateQuestionnaireVersion}
          isLoading={updateQuestionnaireVersionLoading}
        />
      )}
    </div>
  );
};

export default connect(null, { setQuestionnaireToStore })(QuestionnaireShare);
