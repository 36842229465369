import { QuestionTypes } from 'src/Utils/types';

const getConditions = (formData: any, questionsList: any[]) => {
  if (!formData?.condition) {
    return;
  }
  return formData.condition.map(
    ({
      condition,
      answers,
      display,
      connector,
      matrixQuestion,
      question: selectedParentQuestion,
    }: any) => {
      const parentQuestion = questionsList?.find(
        (question: any) => question?._id === selectedParentQuestion?.value
      );
      return {
        answers,
        condition: { ...condition, is: condition?.value === 'is' },
        display: { ...display, show: display?.value === 'show' },
        parentQuestion,
        connector,
        matrixQuestion,
      };
    }
  );
};

export const formatStartingPageData = (
  formData: any,
  data: any,
  questionsList: any[],
  type?: string
) => {
  const conditions = getConditions(formData, questionsList);
  return {
    variables: {
      questionId: data.question._id,
      label: formData[data.questionnaire.defaultLanguage.code].label,
      class: data.question.class,
      type: type || data.question.type,
      questions: data.questionnaire?.supportedLanguages?.map(
        (language: any) => {
          return {
            language,
            text: {
              id: formData[language.code]?.id,
              main: formData[language.code]?.label,
              sub: formData[language.code]?.sub,
            },
            conditions,
          };
        }
      ),
      attributes: {
        languageToggle: formData.languageToggle,
        hideBackButton: formData.hideBackButton,
      },
    },
  };
};

export const formatOpenQuestionData = (
  formData: any,
  data: any,
  questionsList: any[],
  type?: string
) => {
  const conditions = getConditions(formData, questionsList);
  return {
    variables: {
      questionId: data.question._id,
      label: formData[data.questionnaire.defaultLanguage.code].label,
      class: data.question.class,
      type: type || data.question.type,
      questions: data.questionnaire?.supportedLanguages?.map(
        (language: any) => {
          return {
            language,
            text: {
              id: formData[language.code]?.id,
              main: formData[language.code]?.label,
              sub: formData[language.code]?.sub,
              placeholder: formData[language.code]?.placeholder,
            },
            conditions,
          };
        }
      ),
      attributes: {
        inputKeyboard: formData.keyboard,
        maxInput: formData.maxCharacters,
        showSkip: formData.optional || false,
        sentimentAnalysis: formData.sentimentAnalysis,
        hideBackButton: formData.hideBackButton,
        regexMask: formData.regex,
        minNumber: formData.minNumber,
        maxNumber: formData.maxNumber,
      },
    },
  };
};

export const formatRatingQuestionData = (
  formData: any,
  data: any,
  choices: any,
  questionsList: any[],
  type?: string
) => {
  const conditions = getConditions(formData, questionsList);
  data.questionnaire?.supportedLanguages?.forEach((language: any) => {
    formData[language.code] = {
      ...formData[language.code],
      choices: choices[language.code],
    };
  });
  return {
    variables: {
      questionId: data.question._id,
      label: formData[data.questionnaire.defaultLanguage.code].label,
      class: data.question.class,
      type: type || data.question.type,
      questions: data.questionnaire?.supportedLanguages?.map(
        (language: any) => {
          return {
            language,
            text: {
              id: formData[language.code]?.id,
              main: formData[language.code]?.label,
              sub: formData[language.code]?.sub,
              ratings: formData[language.code]?.choices,
            },
            conditions,
          };
        }
      ),
      attributes: {
        optional: formData.optional || false,
        isNPS: formData.isNPS,
        showAsNumbers: !formData.customAnswerText,
        numberOfChoices: formData.isNPS ? 11 : formData.numberOfChoices,
      },
    },
  };
};

export const formatChoicesQuestionData = (
  formData: any,
  data: any,
  choices: any,
  questionsList: any[],
  type?: string
) => {
  const conditions = getConditions(formData, questionsList);
  data.questionnaire?.supportedLanguages?.forEach((language: any) => {
    formData[language.code] = {
      ...formData[language.code],
      choices: choices[language.code],
    };
  });

  return {
    variables: {
      questionId: data.question._id,
      label: formData[data.questionnaire.defaultLanguage.code].label,
      class: data.question.class,
      type: type || data.question.type,
      questions: data.questionnaire?.supportedLanguages?.map(
        (language: any) => {
          return {
            language,
            text: {
              id: formData[language.code]?.id,
              main: formData[language.code]?.label,
              sub: formData[language.code]?.sub,
              choices: formData[language.code]?.choices,
            },
            conditions,
          };
        }
      ),
      attributes: {
        optional: formData.optional || false,
        randomize: formData.randomize,
        multipleSelection: data.singleChoice
          ? !data.singleChoice
          : formData.multipleSelection,
        numberOfChoices:
          type === QuestionTypes.SINGLE_CHOICE ? 1 : formData.numberOfChoices,
        lowestNumberOfChoices:
          type === QuestionTypes.SINGLE_CHOICE
            ? 1
            : formData.lowestNumberOfChoices,
      },
    },
  };
};

export const formatInfoQuestionData = (
  formData: any,
  data: any,
  questionsList: any[],
  type?: string
) => {
  const conditions = getConditions(formData, questionsList);
  return {
    variables: {
      questionId: data.question._id,
      label: formData[data.questionnaire.defaultLanguage.code].label,
      class: data.question.class,
      type: type || data.question.type,
      questions: data.questionnaire?.supportedLanguages?.map(
        (language: any) => {
          return {
            language,
            text: {
              id: formData[language.code]?.id,
              main: formData[language.code]?.label,
              sub: formData[language.code]?.sub,
            },
            conditions,
          };
        }
      ),
      attributes: {
        hideBackButton: formData.hideBackButton,
        showAnswers: formData.showAnswers,
      },
    },
  };
};

export const formatFinalQuestionData = (
  formData: any,
  data: any,
  questionsList: any[],
  type?: string
) => {
  const conditions = getConditions(formData, questionsList);
  return {
    variables: {
      questionId: data.question._id,
      label: formData[data.questionnaire.defaultLanguage.code].label,
      class: data.question.class,
      type: type || data.question.type,
      questions: data.questionnaire?.supportedLanguages?.map(
        (language: any) => {
          return {
            language,
            text: {
              id: formData[language.code]?.id,
              main: formData[language.code]?.label,
              sub: formData[language.code]?.sub,
            },
            conditions,
          };
        }
      ),
      attributes: {
        hideBackButton: formData.hideBackButton,
      },
    },
  };
};

export const formatMatrixChoiceQuestionData = (
  formData: any,
  data: any,
  choices: any,
  answers: any,
  questionsList: any[],
  type?: string
) => {
  const conditions = getConditions(formData, questionsList);
  data.questionnaire?.supportedLanguages?.forEach((language: any) => {
    formData[language.code] = {
      ...formData[language.code],
      choices: choices[language.code],
      answers: answers[language.code],
    };
  });

  return {
    variables: {
      questionId: data.question._id,
      label: formData[data.questionnaire.defaultLanguage.code].label,
      class: data.question.class,
      type: type || data.question.type,
      questions: data.questionnaire?.supportedLanguages?.map(
        (language: any) => {
          return {
            language,
            text: {
              id: formData[language.code]?.id,
              main: formData[language.code]?.label,
              sub: formData[language.code]?.sub,
              choices: formData[language.code]?.choices,
              answers: formData[language.code]?.answers,
            },
            conditions,
          };
        }
      ),
      attributes: {
        optional: formData.optional || false,
        randomize: formData.randomize,
        multipleSelection: data.singleChoice
          ? !data.singleChoice
          : formData.multipleSelection,
      },
    },
  };
};

export const formatMatrixRatingQuestionData = (
  formData: any,
  data: any,
  choices: any,
  answers: any,
  questionsList: any[],
  type?: string
) => {
  const conditions = getConditions(formData, questionsList);
  data.questionnaire?.supportedLanguages?.forEach((language: any) => {
    formData[language.code] = {
      ...formData[language.code],
      choices: choices[language.code],
      answers: answers[language.code],
    };
  });

  return {
    variables: {
      questionId: data.question._id,
      label: formData[data.questionnaire.defaultLanguage.code].label,
      class: data.question.class,
      type: type || data.question.type,
      questions: data.questionnaire?.supportedLanguages?.map(
        (language: any) => {
          return {
            language,
            text: {
              id: formData[language.code]?.id,
              main: formData[language.code]?.label,
              sub: formData[language.code]?.sub,
              choices: formData[language.code]?.choices,
              answers: formData[language.code]?.answers,
            },
            conditions,
          };
        }
      ),
      attributes: {
        optional: formData.optional || false,
        showAsNumbers: !formData.customAnswerText,
        numberOfChoices: formData.numberOfChoices,
      },
    },
  };
};

export const formatCustomHTMLPageQuestionData = (
  formData: any,
  data: any,
  questionsList: any[],
  type?: string
) => {
  const conditions = getConditions(formData, questionsList);
  return {
    variables: {
      questionId: data.question._id,
      label: formData[data.questionnaire.defaultLanguage.code].id,
      class: data.question.class,
      type: type || data.question.type,
      questions: data.questionnaire?.supportedLanguages?.map(
        (language: any) => {
          return {
            language,
            text: {
              id: formData[language.code]?.id,
              html: formData[language.code]?.html,
            },
            conditions,
          };
        }
      ),
      attributes: {
        languageToggle: formData.languageToggle,
        hideBackButton: formData.hideBackButton,
      },
    },
  };
};

export const formatRankingQuestionData = (
  formData: any,
  data: any,
  choices: any,
  questionsList: any[],
  type?: string
) => {
  const conditions = getConditions(formData, questionsList);
  data.questionnaire?.supportedLanguages?.forEach((language: any) => {
    formData[language.code] = {
      ...formData[language.code],
      choices: choices[language.code],
    };
  });

  return {
    variables: {
      questionId: data.question._id,
      label: formData[data.questionnaire.defaultLanguage.code].label,
      class: data.question.class,
      type: type || data.question.type,
      questions: data.questionnaire?.supportedLanguages?.map(
        (language: any) => {
          return {
            language,
            text: {
              id: formData[language.code]?.id,
              main: formData[language.code]?.label,
              sub: formData[language.code]?.sub,
              choices: formData[language.code]?.choices,
            },
            conditions,
          };
        }
      ),
      attributes: {
        optional: formData.optional || false,
        hideBackButton: formData.hideBackButton,
      },
    },
  };
};

export const formatFormQuestionData = (
  formData: any,
  data: any,
  questionsList: any[],
  type?: string
) => {
  return {
    variables: {
      questionId: data.question._id,
      label: formData[data.questionnaire.defaultLanguage.code].label,
      class: data.question.class,
      type: type || data.question.type,
      questions: data.questionnaire?.supportedLanguages?.map(
        (language: any) => {
          return {
            language,
            text: {
              id: formData[language.code]?.id,
              main: formData[language.code]?.label,
              sub: formData[language.code]?.sub,
            },
          };
        }
      ),
      questionsList: questionsList.map((question: any) => question.value),
      attributes: {
        hideBackButton: formData.hideBackButton,
      },
    },
  };
};
