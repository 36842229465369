import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronRight,
  faInfoCircle,
} from '@fortawesome/free-solid-svg-icons';

import { ImodalProps } from '../../Interfaces';
import { customModal } from '../../Constants/ThemeO2T';
import { QuestionsData, QuestionSetData } from '../../Utils/questions';
import {
  ChoiceQuestionTypes,
  MatrixQuestionTypes,
  QuestionSetTypes,
  QuestionTypes,
  RatingQuestionTypes,
  ToastTypes,
} from 'src/Utils/types';
import { useToast } from '../Basic/Toastify';

const AddQuestionModal: React.FC<ImodalProps> = ({
  modalIsOpen,
  toggleModal,
  modalTitle,
  onSubmit,
}) => {
  const { showToast } = useToast();

  const [isOpen, setIsOpen] = useState(false);
  const [questionType, setQuestionType] = useState<{
    type: string;
    index: number;
  } | null>(null);
  const [questionSetType, setQuestionSetType] = useState<{
    type: string;
    title: string;
    index: number;
    description: string;
  } | null>(null);

  useEffect(() => {
    setIsOpen(modalIsOpen);
  }, [modalIsOpen]);

  const closeModal = () => {
    toggleModal();
  };

  const getRatingCount = (questionSet: string | undefined) => {
    switch (questionSet) {
      case RatingQuestionTypes.ONE_TO_TEN:
        return 10;
      case RatingQuestionTypes.ONE_TO_FIVE:
        return 5;
      case RatingQuestionTypes.CSAT:
        return 10;
      case RatingQuestionTypes.CES:
        return 10;
      case RatingQuestionTypes.NPS:
        return 11;
      case RatingQuestionTypes.TNPS:
        return 11;
    }
  };

  const getMatrixQuestionAttributes = (questionSet: string | undefined) => {
    if (questionSet === MatrixQuestionTypes.CHOICE_PAGE) {
      return {
        questionType: MatrixQuestionTypes.CHOICE_PAGE,
        attributes: {
          optional: false,
          randomize: false,
          numberOfChoices: 1,
          lowestNumberOfChoices: 1,
        },
      };
    }
    return {
      questionType: MatrixQuestionTypes.RATING_PAGE,
      attributes: {
        optional: false,
        showAsNumbers: true,
        numberOfChoices: 2,
      },
    };
  };

  const getRatingQuestionAttributes = (questionSet: string | undefined) => {
    return {
      questionType: QuestionTypes.RATING,
      attributes: {
        optional: false,
        isNPS:
          questionSet === RatingQuestionTypes.NPS ||
          questionSet === RatingQuestionTypes.TNPS,
        showAsNumbers: true,
        numberOfChoices: getRatingCount(questionSet),
      },
    };
  };

  const getChoiceQuestionAttributes = (questionSet: string | undefined) => {
    return {
      questionType: questionSet,
      attributes: {
        optional: false,
        randomize: false,
        numberOfChoices:
          questionSet === ChoiceQuestionTypes.SINGLE_CHOICE ? 1 : 2,
        lowestNumberOfChoices: 1,
      },
    };
  };

  const getRankingQuestionAttributes = (questionSet: string | undefined) => {
    return {
      questionType: questionSet,
      attributes: {
        optional: false,
        hideBackButton: false,
      },
    };
  };

  const getOpenQuestionAttributes = (questionSet: string | undefined) => {
    return {
      questionType: QuestionTypes.OPEN,
      attributes: {
        optional: false,
        inputKeyboard:
          questionSet &&
          questionSet.charAt(0) + questionSet.slice(1).toLowerCase(),
        hideBackButton: false,
      },
    };
  };

  const getPageQuestionAttributes = (questionSet: string | undefined) => {
    return {
      questionType: questionSet,
      attributes: {
        hideBackButton: false,
      },
    };
  };

  const getConditionalQuestionAttributes = () => {
    return {
      questionType: QuestionTypes.CONDITIONAL,
    };
  };

  const getFormQuestionAttributes = () => {
    return {
      questionType: QuestionTypes.FORM,
    };
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();

    if (
      !questionType ||
      (!questionSetType && questionType.type !== QuestionSetTypes.CONDITIONAL)
    ) {
      showToast('Please select a question type to proceed', ToastTypes.ERROR);
      return;
    }

    let question: any = null;
    switch (questionType?.type) {
      case QuestionSetTypes.RATING:
        question = getRatingQuestionAttributes(questionSetType?.type);
        break;
      case QuestionSetTypes.CHOICE:
        question = getChoiceQuestionAttributes(questionSetType?.type);
        break;
      case QuestionSetTypes.OPEN:
        question = getOpenQuestionAttributes(questionSetType?.type);
        break;
      case QuestionSetTypes.PAGE:
        question = getPageQuestionAttributes(questionSetType?.type);
        break;
      case QuestionSetTypes.CONDITIONAL:
        question = getConditionalQuestionAttributes();
        break;
      case QuestionSetTypes.MATRIX:
        question = getMatrixQuestionAttributes(questionSetType?.type);
        break;
      case QuestionSetTypes.RANKING:
        question = getRankingQuestionAttributes(questionSetType?.type);
        break;
      case QuestionSetTypes.FORM:
        question = getFormQuestionAttributes();
    }
    onSubmit({ question });
    setQuestionType(null);
    setQuestionSetType(null);
  };

  const selectQuestionType = (type: string, index: number) => {
    setQuestionType({ type, index });
    setQuestionSetType(null);
  };
  const selectQuestionSetType = (
    type: string,
    index: number,
    description: string,
    title: string
  ) => {
    setQuestionSetType({ type, index, description, title });
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        style={{ content: { ...customModal.content, width: '70%' } }}
      >
        <div className='o2t-modal pl-4 pr-4 pt-4 pb-3'>
          <div className='o2t-modal__header'>
            <h2 className='o2t-modal__header-question-title'>{modalTitle}</h2>
          </div>

          <div className='o2t-modal__container'>
            <div className='q-types-wrapper'>
              <div className='pb-0'>
                <label>Choose type of question:</label>
                <ul className='new-qTypes'>
                  {QuestionsData &&
                    QuestionsData.map((question) => {
                      return (
                        <li
                          className={`new-question ${
                            questionType?.index === question.id
                              ? 'selected'
                              : ''
                          }`}
                          key={question.id}
                          onClick={() =>
                            selectQuestionType(question.type, question.id)
                          }
                        >
                          <div className='d-flex question-text-wrapper'>
                            <div
                              className={`q-info__question ${
                                questionType?.index === question.id
                                  ? 'selected'
                                  : ''
                              }`}
                            >
                              <div className='q-info__question--title mt-3 mb-3'>
                                {question.label}
                              </div>
                            </div>
                          </div>
                        </li>
                      );
                    })}
                </ul>
              </div>
              <div className='divider'></div>
              <div>
                <label>Choose questionset:</label>
                <div className='new-qTypes__question-set'>
                  <div className='question-set-container'>
                    {questionType &&
                      QuestionSetData?.[questionType?.type]?.map(
                        (page: any) => {
                          return (
                            <div
                              className={`new-question ${
                                questionSetType?.index === page.id
                                  ? 'selected'
                                  : ''
                              }`}
                              key={page.id}
                              onClick={() =>
                                selectQuestionSetType(
                                  page.type,
                                  page.id,
                                  page.description,
                                  page.title
                                )
                              }
                            >
                              <div className='d-flex questionnaire-modal-wrapper '>
                                <div className='q-info__question'>
                                  <div className='q-info__question--title'>
                                    {page.label}
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        }
                      )}
                  </div>
                  <div className='q-desc-container'>
                    {questionSetType && (
                      <div>
                        <div className='question-info-container'>
                          <span className='mr-1'>
                            <FontAwesomeIcon icon={faInfoCircle} />
                          </span>
                          <div>
                            <span className='mb-1'>
                              {questionSetType.title}
                            </span>
                            <span>
                              <br />
                              {questionSetType?.description}
                            </span>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className='o2t-modal__footer'>
              <button onClick={closeModal} className='link-button mr-2'>
                Cancel
              </button>
              <button onClick={handleSubmit}>
                Add Question
                <span>
                  <FontAwesomeIcon className='ml-3' icon={faChevronRight} />
                </span>
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default AddQuestionModal;
