import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';

import { IPage } from '../Interfaces';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import Loading from '../Components/Basic/Loading';
import { connect } from 'react-redux';
import { useLazyQuery, useMutation } from '@apollo/client';
import AnalyticsConfigGrid from 'src/Components/SmartAnalyticsModule/AnalyticsConfigGrid';
import AddEditAnalyitcsConfigModal from 'src/Components/SmartAnalyticsModule/AddEditAnalyticsConfigModal';
import { setQuestionnaireDataToStore } from 'src/States/Actions/questionnaireAction';
import { GET_PAGINATED_QUESTIONNAIRES } from 'src/Query/questionnaires.query';
import {
  ADD_ANALYTICS_CONFIGURATION,
  DELETE_ANALYTICS_CONFIGURATION,
  GET_PAGINATED_ANALYTICS_CONFIGURATIONS,
  RUN_ANALYTICS_CONFIGURATION,
  UPDATE_ANALYTICS_CONFIGURATION,
} from 'src/Query/smartAnalytics.query';
import { setAnalyticsConfigurationDataToStore } from 'src/States/Actions/analyticsConfigurationAction';
import { DeleteAnalyticsConfigModal } from 'src/Components/SmartAnalyticsModule/DeleteAnalyticsConfigModal';
import { useToast } from 'src/Components/Basic/Toastify';
import { ToastTypes } from 'src/Utils/types';

const SmartAnalytics: React.FunctionComponent<IPage> = (props: any) => {
  const { showToast } = useToast();

  const {
    license,
    total,
    next,
    previous,
    searchData,
    questionnaires,
    analyticsConfigurations,
  } = props;

  const [questionnairePaginationDetails, setQuestionnairePaginationDetails] =
    useState<any>({
      limit: 1000,
      page: 1,
      isArchived: false,
    });
  const [configPaginationDetails, setConfigPaginationDetails] = useState<any>({
    limit: 10,
    page: 1,
  });
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openAddEditModal, setOpenAddEditModal] = useState(false);
  const [deleteConfig, setDeleteConfig] = useState(null);
  const [editConfigModal, setEditConfigModal] = useState(false);
  const [selectedConfig, setSelectedConfig] = useState<any>(null);

  const toggleDeleteModal = () => {
    setOpenDeleteModal(!openDeleteModal);
  };

  const toggleAddEditModal = () => {
    setOpenAddEditModal(!openAddEditModal);
  };

  const handleSortByColumn = (sortBy: { name: string }, sortOrder: string) => {
    setConfigPaginationDetails({
      limit: configPaginationDetails.limit,
      page: 1,
      sortBy: sortBy.name.toUpperCase().replaceAll(' ', '_'),
      sortOrder: sortOrder.toUpperCase(),
      searchTerm: searchData.searchTerm,
    });
  };

  const handlePageChange = (paginationDetails: any) => {
    setConfigPaginationDetails({
      ...paginationDetails,
      searchTerm: searchData.search,
    });
  };

  const handleDeleteConfigSelect = (analyticsConfig: any) => {
    setDeleteConfig(analyticsConfig);
    toggleDeleteModal();
  };

  const [getQuestionnaires] = useLazyQuery(GET_PAGINATED_QUESTIONNAIRES, {
    errorPolicy: 'all',
    onCompleted: (completedData: any) => {
      if (completedData) {
        props.setQuestionnaireDataToStore(completedData.questionnaires);
      }
    },
    onError: (error: any) => {
      showToast(error.message, ToastTypes.ERROR);
      console.error(error);
    },
  });

  const [getAnalyticsConfigurations] = useLazyQuery(
    GET_PAGINATED_ANALYTICS_CONFIGURATIONS,
    {
      errorPolicy: 'all',
      onCompleted: (completedData: any) => {
        if (completedData) {
          props.setAnalyticsConfigurationDataToStore(
            completedData.analyticsConfigurations
          );
        }
      },
      onError: (error: any) => {
        showToast(error.message, ToastTypes.ERROR);
        console.error(error);
      },
    }
  );

  const [callAddAPI] = useMutation(ADD_ANALYTICS_CONFIGURATION, {
    refetchQueries: [
      {
        query: GET_PAGINATED_ANALYTICS_CONFIGURATIONS,
        variables: {
          limit: 10,
          page: 1,
          searchTerm: '',
          licenseId: license._id,
        },
      },
    ],
    errorPolicy: 'none',
    onCompleted: (data) => {
      getAnalyticsConfigurations({
        variables: {
          ...configPaginationDetails,
          searchTerm: '',
          licenseId: license._id,
        },
      });
      toggleAddEditModal();
      showToast('Analytics configuration created successfully');
    },
    onError: (error: any) => {
      showToast(error.message, ToastTypes.ERROR);
      console.error(error);
    },
  });

  const [callUpdateAPI] = useMutation(UPDATE_ANALYTICS_CONFIGURATION, {
    refetchQueries: [
      {
        query: GET_PAGINATED_ANALYTICS_CONFIGURATIONS,
        variables: {
          limit: 10,
          page: 1,
          searchTerm: '',
          licenseId: license._id,
        },
      },
    ],
    errorPolicy: 'none',
    onCompleted: (data) => {
      getAnalyticsConfigurations({
        variables: {
          ...configPaginationDetails,
          searchTerm: '',
          licenseId: license._id,
        },
      });
      toggleAddEditModal();
      setEditConfigModal(false);
      showToast('Analytics configuration updated successfully');
    },
    onError: (error: any) => {
      showToast(error.message, ToastTypes.ERROR);
      console.error(error);
    },
  });

  const [callDeleteAPI] = useMutation(DELETE_ANALYTICS_CONFIGURATION, {
    refetchQueries: [
      {
        query: GET_PAGINATED_ANALYTICS_CONFIGURATIONS,
        variables: {
          ...configPaginationDetails,
          searchTerm: '',
          licenseId: license._id,
        },
      },
    ],
    errorPolicy: 'none',
    onCompleted: (data) => {
      getAnalyticsConfigurations({
        variables: {
          ...configPaginationDetails,
          searchTerm: '',
          licenseId: license._id,
        },
      });
      toggleDeleteModal();
      showToast('Analytics configuration deleted successfully');
    },
    onError: (error: any) => {
      showToast(error.message, ToastTypes.ERROR);
      console.error(error);
    },
  });

  const [runAnalyticsConfiguration] = useLazyQuery(
    RUN_ANALYTICS_CONFIGURATION,
    {
      onCompleted: (completedData) => {
        const { regressionData } = completedData?.runAnalyticsConfiguration;
        const csvData = convertToCSV(regressionData);
        exportData(
          csvData,
          `${selectedConfig?.name || 'data'}.csv`,
          'text/csv;charset=utf-8;'
        );
      },
      onError: (error: any) => {
        showToast(error.message, ToastTypes.ERROR);
        console.error(error);
      },
    }
  );

  useEffect(() => {
    getQuestionnaires({
      variables: {
        ...questionnairePaginationDetails,
        licenseId: license?._id,
      },
    });
  }, [license, questionnairePaginationDetails, getQuestionnaires]);

  useEffect(() => {
    getAnalyticsConfigurations({
      variables: {
        ...configPaginationDetails,
        licenseId: license?._id,
      },
    });
  }, [license, configPaginationDetails, getAnalyticsConfigurations]);

  useEffect(() => {
    setConfigPaginationDetails((prevPaginationDetails: any) => {
      return {
        ...prevPaginationDetails,
        searchTerm: searchData.searchTerm,
      };
    });
  }, [searchData]);

  const convertToCSV = (regressionData: any) => {
    const { averages, correlations, slopeCoefficients } = regressionData;
    const csvContent = [];

    // Add headers
    csvContent.push(['Factor', 'Average', 'Correlation', 'Slope Coefficient']);

    const headers = Object.keys(averages);
    headers.forEach((header) => {
      const row = [
        header,
        averages[header],
        correlations[header],
        slopeCoefficients[header],
      ];
      csvContent.push(row);
    });

    // Add dependent factor average
    const dependentVar = headers[headers.length - 1];
    csvContent.push([
      dependentVar + ' (Dependent)',
      averages[dependentVar],
      '',
      '',
    ]);

    // Convert to CSV format
    const csvRows = csvContent.map((row) => row.join(','));
    return csvRows.join('\n');
  };

  const handleSubmit = (params: any) => {
    if (editConfigModal) {
      callUpdateAPI({
        variables: {
          configId: selectedConfig._id,
          ...params.variables,
          licenseId: license._id,
        },
      });
    } else {
      callAddAPI({
        variables: {
          ...params.variables,
          licenseId: license._id,
        },
      });
    }
  };

  const handleScroll = () => {
    setQuestionnairePaginationDetails({
      ...questionnairePaginationDetails,
      limit: questionnairePaginationDetails.limit + 10,
    });
  };

  const handleRowClick = (row: any) => {
    setSelectedConfig(row);
    setEditConfigModal(true);
    setOpenAddEditModal(true);
  };

  const exportData = (data: any, fileName: string, type: string) => {
    const blob = new Blob([data], { type });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = fileName;
    a.click();
    window.URL.revokeObjectURL(url);
  };

  const onRunConfig = (config: any) => {
    const configId = config._id;
    setSelectedConfig(config);
    runAnalyticsConfiguration({
      variables: {
        configId,
      },
    });
  };

  return (
    <div className='smart-analytics-screen'>
      <div className='page-header'>
        <div className='page-header__title'>
          <h2>Analytics configurations</h2>
        </div>
        <div className='page-header__actions'>
          <button
            onClick={(event: any) => {
              event.preventDefault();
              setSelectedConfig(null);
              toggleAddEditModal();
            }}
            className='btn-theme'
          >
            Add Configuration
            <span>
              <FontAwesomeIcon className='ml-3' icon={faChevronRight} />
            </span>
          </button>
        </div>
      </div>
      {openDeleteModal && (
        <DeleteAnalyticsConfigModal
          modalIsOpen={openDeleteModal}
          toggleModal={toggleDeleteModal}
          modalTitle={'Delete Analytics Configuration'}
          modalData={deleteConfig}
          onDelete={callDeleteAPI}
        />
      )}
      {openAddEditModal && (
        <AddEditAnalyitcsConfigModal
          modalIsOpen={openAddEditModal}
          toggleModal={toggleAddEditModal}
          isEdit={editConfigModal}
          modalData={{
            selectedConfig,
            questionnaires,
          }}
          onSubmit={handleSubmit}
          onScroll={handleScroll}
        />
      )}
      <div className='grid-wrapper'>
        <AnalyticsConfigGrid
          data={{
            license,
            analyticsConfigs: analyticsConfigurations,
            total: total,
            paginationDetails: configPaginationDetails,
            next: next,
            previous: previous,
            onRunConfig,
            onRowClick: handleRowClick,
            handlePageChange,
            handleSortByColumn,
            handleDeleteConfigSelect,
          }}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    license: state.license,
    questionnaires: state.questionnaires.results,
    analyticsConfigurations: state.analyticsConfigurations.results,
    hasNext: state.questionnaires.hasNext,
    next: state.questionnaires.next,
    hasPrevious: state.questionnaires.hasPrevious,
    previous: state.questionnaires.previous,
    total: state.questionnaires.total,
    searchData: state.searchData,
  };
};

export default connect(mapStateToProps, {
  setQuestionnaireDataToStore,
  setAnalyticsConfigurationDataToStore,
})(
  withAuthenticationRequired(SmartAnalytics, {
    onRedirecting: () => <Loading />,
  })
);
