import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { useLazyQuery, useMutation } from '@apollo/client';

import { IPage } from '../Interfaces';
import {
  displayToastMessage,
  setProductDataToStore,
} from '../States/Actions/productAction';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import Loading from '../Components/Basic/Loading';
import AccessControl from '../Components/AccessControl';
import Alert from '../Components/Basic/AlertComponent';
import ProductGrid from '../Components/ProductModule/ProductGrid';
import { DeleteProductModal } from '../Components/ProductModule/DeleteProductModal';
import {
  ADD_PRODUCT,
  UPDATE_PRODUCT,
  DELETE_PRODUCT,
  GET_PAGINATED_PRODUCTS,
} from 'src/Query/products.query';
import AddEditProductModal from 'src/Components/ProductModule/AddEditProductModal';
import { useToast } from 'src/Components/Basic/Toastify';
import { ToastTypes } from 'src/Utils/types';

const Products: React.FunctionComponent<IPage> = ({
  setProductDataToStore,
  products,
  total,
  next,
  previous,
  searchData,
}: any) => {
  const { showToast } = useToast();

  const [paginationDetails, setPaginationDetails] = useState<any>({
    limit: 10,
    page: 1,
    searchTerm: searchData.searchTerm,
  });
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [editProductModal, setEditProductModal] = useState(false);
  const [deleteProduct, setDeleteProduct] = useState(null);
  const [openAddEditProductModal, setOpenAddEditProductModal] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);

  const toggleDeleteModal = () => {
    setOpenDeleteModal(!openDeleteModal);
  };

  const toggleAddEditModal = (isEdit = false) => {
    setEditProductModal(isEdit);
    setOpenAddEditProductModal(!openAddEditProductModal);
  };

  const handleSortByColumn = (sortBy: { name: string }, sortOrder: string) => {
    setPaginationDetails({
      limit: paginationDetails.limit,
      page: 1,
      sortBy: sortBy.name.toUpperCase().replaceAll(' ', '_'),
      sortOrder: sortOrder.toUpperCase(),
      searchTerm: searchData.searchTerm,
    });
  };

  const handlePageChange = (paginationDetails: any) => {
    setPaginationDetails({
      ...paginationDetails,
      searchTerm: searchData.search,
    });
  };

  const handleDeleteProductSelect = (Product: any) => {
    setDeleteProduct(Product);
    toggleDeleteModal();
  };

  const handleProductSelect = (row: any) => {
    setSelectedProduct(row);
    toggleAddEditModal(true);
  };

  const [fetchProducts, { loading, error }] = useLazyQuery(
    GET_PAGINATED_PRODUCTS,
    {
      errorPolicy: 'all',
      onCompleted: (completedData) => {
        if (completedData) {
          setProductDataToStore(completedData.products);
        }
      },
    }
  );

  useEffect(() => {
    fetchProducts({
      variables: { ...paginationDetails, searchTerm: searchData.searchTerm },
    });
  }, [paginationDetails, searchData, fetchProducts]);

  useEffect(() => {
    setPaginationDetails((prevPaginationDetails: any) => {
      return {
        ...prevPaginationDetails,
        searchTerm: searchData.searchTerm,
      };
    });
  }, [searchData]);

  const [addProduct, { error: addProductError, loading: addProductLoading }] =
    useMutation(ADD_PRODUCT, {
      errorPolicy: 'none',
      onCompleted: () => {
        showToast('Product added successfully');
        setPaginationDetails({
          limit: 10,
          page: 1,
          searchTerm: '',
        });
        toggleAddEditModal();
      },
      onError: (error: any) => {
        showToast(error.message, ToastTypes.ERROR);
        console.error(error);
      },
    });

  const [
    editProduct,
    { error: editProductError, loading: editProductLoading },
  ] = useMutation(UPDATE_PRODUCT, {
    errorPolicy: 'none',
    onCompleted: () => {
      showToast('Product updated successfully');
      setPaginationDetails({
        limit: 10,
        page: 1,
        searchTerm: '',
      });
      toggleAddEditModal();
    },
    onError: (error: any) => {
      showToast(error.message, ToastTypes.ERROR);
      console.error(error);
    },
  });

  const [
    deleteSelectedProduct,
    { error: deleteProductError, loading: deleteProductLoading },
  ] = useMutation(DELETE_PRODUCT, {
    errorPolicy: 'none',
    onCompleted: () => {
      showToast('Product deleted successfully');
      setPaginationDetails({
        limit: 10,
        page: 1,
        searchTerm: '',
      });
      toggleDeleteModal();
    },
    onError: (error: any) => {
      showToast(error.message, ToastTypes.ERROR);
      console.error(error);
    },
  });

  const handleSubmit = (params: any) => {
    if (editProductModal) {
      editProduct(params);
    } else {
      addProduct(params);
    }
  };

  return (
    <div className='products-screen'>
      <AccessControl
        allowedPermissions={['view:products_page']}
        renderNoAccess={() => (
          <Alert
            message={
              'Unauthorized. You do not have permission to view products page'
            }
          />
        )}
      >
        <div className='page-header'>
          <div className='page-header__title'>
            <h2>Products</h2>
          </div>
          <div className='page-header__actions'>
            <button
              onClick={(event: any) => {
                event.preventDefault();
                setSelectedProduct(null);
                toggleAddEditModal();
              }}
              className='btn-theme'
            >
              Add Products
              <span>
                <FontAwesomeIcon className='ml-3' icon={faChevronRight} />
              </span>
            </button>
          </div>
        </div>
        {openDeleteModal && (
          <DeleteProductModal
            modalIsOpen={openDeleteModal}
            toggleModal={toggleDeleteModal}
            modalTitle={'Delete Product'}
            modalData={deleteProduct}
            error={deleteProductError}
            onDelete={deleteSelectedProduct}
            isLoading={deleteProductLoading}
          />
        )}
        {openAddEditProductModal && (
          <AddEditProductModal
            modalIsOpen={openAddEditProductModal}
            toggleModal={toggleAddEditModal}
            isEdit={editProductModal}
            modalData={{
              selectedProduct,
            }}
            onSubmit={handleSubmit}
            error={addProductError || editProductError}
            isLoading={addProductLoading || editProductLoading}
          />
        )}
        <div className='grid-wrapper'>
          <ProductGrid
            data={{
              products,
              total: total,
              loading,
              error,
              paginationDetails,
              next: next,
              previous: previous,
              handlePageChange,
              handleSortByColumn,
              handleDeleteProductSelect,
              setSelectedProduct,
              handleProductSelect,
            }}
          />
        </div>
      </AccessControl>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    user: state.user,
    products: state.products.results,
    hasNext: state.products.hasNext,
    next: state.products.next,
    hasPrevious: state.products.hasPrevious,
    previous: state.products.previous,
    total: state.products.total,
    searchData: state.searchData,
  };
};

export default connect(mapStateToProps, {
  setProductDataToStore,
  displayToastMessage,
})(
  withAuthenticationRequired(Products, {
    onRedirecting: () => <Loading />,
  })
);
