import React, { useState, useCallback, useEffect, useMemo } from 'react';
import Modal from 'react-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { useForm, Controller } from 'react-hook-form';
import Select from 'react-select';

import { customModal } from '../../Constants/ThemeO2T';
import { useQuery } from '@apollo/client';
import { GET_LICENSES_BY_IDS } from '../../Query/licenses.query';
import { GET_USER_ROLES } from '../../Query/users.query';
import AccessControl from '../AccessControl';

type NewUserData = {
  email: string;
  password: string;
  name: string;
  userRole: string;
  licenses?: string[];
  language: string;
  mfa: boolean;
};

const AddEditModal: React.FC<any> = ({
  modalIsOpen,
  toggleModal,
  modalTitle,
  modalData,
  onSubmit,
  error,
  loadLicenseOptions,
  handleLicenseDropdownScroll,
  isLoading,
}) => {
  const [name, setName] = useState(modalData?.name || '');
  const [email, setEmail] = useState(modalData?.email || '');
  const [isMFAEnabled, setIsMFAEnabled] = useState(
    modalData?.user_metadata?.use_mfa
  );
  const [password, setPassword] = useState('');
  const [rolesSelectDisabled, setRolesSelectDisabled] = useState(true);
  const [skipFetchRole, setSkipFetchRole] = useState(true);
  const [skipFetchLicenses, setSkipFetchLicenses] = useState(true);

  const isEditMode = modalTitle !== 'Add User';
  const [licenses, setLicenses] = useState([]);

  const {
    control,
    register,
    formState: { errors },
    reset,
    getValues,
    setValue,
  } = useForm<NewUserData>();

  const roleOptions: any = useMemo(
    () => [
      { label: 'None', value: 'none' },
      { label: 'Admin', value: 'admin' },
      { label: 'Owner', value: 'owner' },
      { label: 'Initiator', value: 'initiator' },
    ],
    []
  );

  const languageOptions: any = useMemo(
    () => [{ label: 'English', value: 'en' }],
    []
  );

  const resetForm = useCallback(async () => {
    reset({ name: '', password: '', email: '', userRole: 'none', mfa: true });
  }, [reset]);

  const closeModal = () => {
    toggleModal();
    resetForm();
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();
    const { name, password, email, licenses, userRole, mfa } = getValues();
    const jsonRole = JSON.parse(JSON.stringify(userRole)); // is there a better way to do this?

    const params: any = {
      user_id: modalData?._id,
      name,
      email,
      password,
      role: jsonRole.value,
      user_metadata: {
        use_mfa: mfa,
      },
      app_metadata: {
        licenses: licenses?.map((license: any) => license.value) || [],
      },
    };

    if (isEditMode) {
      delete params['email'];
    }

    onSubmit({
      variables: params,
    });
  };

  let timerId: any;
  const handleLicenseOptionsSearch = (searchTerm: string) => {
    if (timerId) {
      clearInterval(timerId);
    }
    timerId = setTimeout(() => {
      loadLicenseOptions({ limit: 10, searchTerm });
    }, 500);
  };

  const { loading, data } = useQuery(GET_LICENSES_BY_IDS, {
    variables: { ids: modalData?.app_metadata?.licenses },
    errorPolicy: 'all',
    skip: skipFetchLicenses,
  });

  useEffect(() => {
    if (data) {
      const selectedLicenses = data.licensesByIds.map((license: any) => {
        return {
          label: license.name,
          value: license._id.toString(),
        };
      });
      setValue('licenses', selectedLicenses);
    }
  }, [data, setValue]);

  const { loading: userRoleLoading, data: userRoleData } = useQuery(
    GET_USER_ROLES,
    {
      variables: { user_id: modalData?._id },
      errorPolicy: 'all',
      skip: skipFetchRole,
    }
  );

  useEffect(() => {
    // execute gql queries
    if (modalData && modalData._id) {
      // is edit and does have value in _id
      setSkipFetchRole(false);
      setSkipFetchLicenses(false);

      const licenses = modalData?.licenses.map((license: any) => {
        return {
          label: license.name,
          value: license._id,
        };
      });
      setLicenses(licenses);
    }
  }, [modalData]);

  useEffect(() => {
    let userRole = roleOptions[0];
    if (
      userRoleData &&
      userRoleData.userRoles &&
      userRoleData.userRoles.length > 0
    ) {
      const role = roleOptions.find(
        (option: any) => option.value === userRoleData.userRoles[0].name
      );
      userRole = role ? role : roleOptions[0];

      setValue('userRole', userRole);
      setRolesSelectDisabled(false);
    }
  }, [userRoleData, setValue, roleOptions]);

  return (
    <>
      <Modal
        isOpen={modalIsOpen}
        style={customModal}
        onRequestClose={closeModal}
        contentLabel='Add User Modal'
      >
        <div className='o2t-modal'>
          <div className='o2t-modal__header'>
            <h2 className='o2t-modal__header-title'>{modalTitle}</h2>
          </div>

          <div className='o2t-modal__container'>
            <form onSubmit={handleSubmit}>
              {isEditMode && (
                <div className='form-group'>
                  <label>
                    Name <span className='mandatory'>*</span>
                  </label>
                  <input
                    data-testid='user-name-input'
                    {...register('name', { required: true })}
                    onChange={(e) => setName(e.target.value)}
                    type='text'
                    value={name}
                  />
                  {errors.name && (
                    <span className='error-msg'>Name is required.</span>
                  )}
                </div>
              )}

              <div className='form-group'>
                <label>
                  Email <span className='mandatory'>*</span>
                </label>
                <input
                  type='email'
                  data-testid='user-email-input'
                  {...register('email', { required: 'Email is required.' })}
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                />
                {errors.email && (
                  <span className='error-msg'>{errors.email?.message}</span>
                )}
              </div>

              {!isEditMode && (
                <div className='form-group'>
                  <label>
                    Password <span className='mandatory'>*</span>
                  </label>
                  <input
                    data-testid='user-password-input'
                    {...register('password', {
                      required: 'Password is required.',
                    })}
                    onChange={(e) => setPassword(e.target.value)}
                    type='password'
                    value={password}
                  />
                  {errors.password && (
                    <span className='error-msg'>
                      {errors.password?.message}
                    </span>
                  )}
                </div>
              )}
              <div className='form-group d-flex'>
                <div className='w-50 pr-4'>
                  <label>Role</label>
                  <Controller
                    control={control}
                    name='userRole'
                    rules={{ required: false }}
                    render={({ field: { onChange, value } }) => (
                      <Select
                        isDisabled={rolesSelectDisabled && userRoleLoading}
                        isLoading={isEditMode && userRoleLoading}
                        options={roleOptions}
                        onChange={onChange}
                        value={value}
                      />
                    )}
                  />
                </div>
                <div className='w-50'>
                  <label>Language</label>
                  <Controller
                    control={control}
                    name='language'
                    rules={{ required: false }}
                    render={({ field: { onChange, value } }) => (
                      <Select
                        defaultValue={languageOptions[0]}
                        options={languageOptions}
                        onChange={onChange}
                        value={value}
                      />
                    )}
                  />
                </div>
              </div>
              <div className='form-group checkbox-field'>
                <label className='full-width-label'>Enable MFA</label>
                <input
                  className='checkbox'
                  type='checkbox'
                  {...register('mfa')}
                  onChange={(e) => setIsMFAEnabled(e.target.checked)}
                  checked={isMFAEnabled}
                />
              </div>

              {isEditMode && (
                <div className='form-group'>
                  <label>Licenses</label>
                  <AccessControl
                    allowedPermissions={['assign:user_to_license']}
                    renderNoAccess={() => (
                      <Controller
                        control={control}
                        name='licenses'
                        rules={{ required: false }}
                        render={({ field: { onChange, value } }) => (
                          <Select
                            isDisabled={true}
                            placeholder={
                              isEditMode && loading ? 'Loading...' : 'Search...'
                            }
                            isMulti
                            key={'licenses'}
                            options={[]}
                            value={value}
                            onInputChange={handleLicenseOptionsSearch}
                            onMenuScrollToBottom={handleLicenseDropdownScroll}
                            onChange={onChange}
                            isLoading={isEditMode && loading}
                          />
                        )}
                      />
                    )}
                  >
                    <Controller
                      control={control}
                      name='licenses'
                      rules={{ required: false }}
                      render={({ field: { onChange, value } }) => (
                        <Select
                          placeholder={
                            isEditMode && loading ? 'Loading...' : 'Search...'
                          }
                          isMulti
                          key={'licenses'}
                          options={licenses}
                          value={value}
                          onInputChange={handleLicenseOptionsSearch}
                          onMenuScrollToBottom={handleLicenseDropdownScroll}
                          onChange={onChange}
                          isLoading={isEditMode && loading}
                        />
                      )}
                    />
                  </AccessControl>

                  {errors.email && (
                    <span className='error-msg'>{errors.email?.message}</span>
                  )}
                </div>
              )}

              <div className='alert-wrapper'>
                {error && <p>{error.message}</p>}
              </div>

              <div className='o2t-modal__footer'>
                <button
                  onClick={closeModal}
                  className='link-button mr-2'
                  disabled={isLoading}
                >
                  Cancel
                </button>
                <AccessControl allowedPermissions={['update:user']}>
                  <button
                    type='submit'
                    data-testid='user-save-btn'
                    disabled={isLoading}
                  >
                    {modalTitle === 'Add User' ? 'Add user' : 'Save'}
                    <span>
                      <FontAwesomeIcon className='ml-3' icon={faChevronRight} />
                    </span>
                  </button>
                </AccessControl>
              </div>
            </form>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default AddEditModal;
