import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Fragment, useCallback, useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { Controller, useFormContext } from 'react-hook-form';
import ToggleSwitch from 'src/Components/Basic/ToggleSwitch';
import { QuestionTypes } from 'src/Utils/types';
import { sanitizeQuillEditorOutput } from 'src/Utils/util';
import QuestionTextInput from '../QuestionTextInput';

const MatrixQuestion = (props: any) => {
  const { data } = props;
  const { choices, setChoices, answers, setAnswers } = data;

  const [type, setType] = useState(data.question?.type);

  const { control, formState, register, reset, watch, setValue, getValues } =
    useFormContext<any>();

  const [customAnswerText, numberOfChoices] = watch([
    'customAnswerText',
    'numberOfChoices',
  ]);

  const { errors } = formState;

  const handleFocus = (event: any) => {
    event.currentTarget?.select();
  };

  const reorder = (list: any, startIndex: number, endIndex: number) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const initializeAnswers = useCallback(() => {
    const initalAnswers: any = {};
    data.questionnaire?.supportedLanguages?.forEach((language: any) => {
      initalAnswers[language.code] = [
        { _id: '1', content: '' },
        { _id: '2', content: '' },
      ];
    });

    return initalAnswers;
  }, [data.questionnaire]);

  const initializeChoices = useCallback(() => {
    const initalChoices: any = {};
    data.questionnaire?.supportedLanguages?.forEach((language: any) => {
      initalChoices[language.code] = [
        { _id: '1', content: '' },
        { _id: '2', content: '' },
      ];
    });

    return initalChoices;
  }, [data.questionnaire]);

  const setChoiceValues = useCallback(
    (question: any) => {
      let choices: any = {};

      if (question?.questions?.length > 0) {
        question.questions.forEach((item: any) => {
          if (item?.text?.choices) {
            choices[item.language.code] = item.text.choices;
          } else {
            choices = initializeChoices();
          }
        });
      } else {
        choices = initializeChoices();
      }

      setChoices(choices);
    },
    [initializeChoices, setChoices]
  );

  const setAnswerValues = useCallback(
    (question: any) => {
      let answers: any = {};

      if (question?.questions?.length > 0) {
        question.questions.forEach((item: any) => {
          if (item?.text?.answers) {
            answers[item.language.code] = item.text.answers;
          } else {
            answers = initializeAnswers();
          }
        });
      } else {
        answers = initializeAnswers();
      }
      setAnswers(answers);
    },
    [initializeAnswers, setAnswers]
  );

  const handleAddAnswers = (event: any) => {
    event.preventDefault();

    const defaultLanguage = data.questionnaire?.defaultLanguage;
    const answerCount = answers[defaultLanguage.code].length;

    if (answerCount < 15) {
      const newState = { ...answers };

      data.questionnaire?.supportedLanguages?.forEach((language: any) => {
        newState[language.code] = [
          ...answers[language.code],
          {
            _id: `${answers[language.code].length + 1}`,
            content: '',
          },
        ];
      });

      setAnswers(newState);
    }
  };

  const handleAddChoices = (event: any) => {
    event.preventDefault();

    const defaultLanguage = data.questionnaire?.defaultLanguage;
    const choiceCount = choices[defaultLanguage.code].length;

    if (choiceCount < 15) {
      const newState = { ...choices };

      data.questionnaire?.supportedLanguages?.forEach((language: any) => {
        newState[language.code] = [
          ...choices[language.code],
          {
            _id: `${choices[language.code].length + 1}`,
            content: '',
          },
        ];
      });

      setChoices(newState);
    }
  };

  const getQuestionsDataForForm = (questions: any, questionnaire: any) => {
    const formData: any = {};

    questionnaire?.supportedLanguages?.forEach((language: any) => {
      const question = questions.questions?.find(
        (question: any) => question?.language?.code === language.code
      );

      formData[language.code] = {
        id: question?.text?.id || null,
        label: question?.text?.main || null,
        sub: question?.text?.sub || null,
        choices: question?.text?.choices || [],
      };
    });
    return formData;
  };

  const getAttributes = (questionSetType: string, attributes: any) => {
    if (!questionSetType || !attributes) return {};
    if (questionSetType === QuestionTypes.CHOICE_PAGE) {
      return {
        optional: attributes?.optional,
        randomize: attributes?.randomize,
        numberOfChoices: attributes?.numberOfChoices,
        lowestNumberOfChoices: attributes?.lowestNumberOfChoices,
      };
    }

    return {
      optional: attributes?.optional,
      customAnswerText: !attributes?.showAsNumbers,
      numberOfChoices: attributes?.numberOfChoices,
    };
  };

  const addAnswer = useCallback(
    (answers: any, languageCode?: string) => {
      const language = languageCode || data.questionnaire?.defaultLanguage.code;
      const answerCount = answers[language]?.length;

      if (answerCount < 11) {
        const newState = { ...answers };

        data.questionnaire?.supportedLanguages?.forEach((language: any) => {
          newState[language.code] = [
            ...answers[language.code],
            {
              _id: `${answers[language.code].length + 1}`,
              content: answers[language.code].length + 1,
            },
          ];
        });
        return newState;
      }
      return answers;
    },
    [data.questionnaire]
  );

  const getInitialChoiceObject = useCallback(() => {
    const initialChoices: any = {};
    data.questionnaire?.supportedLanguages?.forEach((language: any) => {
      initialChoices[language.code] = [];
    });
    return initialChoices;
  }, [data.questionnaire]);

  const addMultipleAnswers = useCallback(
    (choices: any, choiceCount: number) => {
      const updatedChoices = { ...choices };
      data.questionnaire?.supportedLanguages?.forEach(
        ({ code }: { code: string }) => {
          while (updatedChoices[code].length < choiceCount) {
            const choiceList = addAnswer(updatedChoices, code);
            updatedChoices[code] = choiceList[code];
          }
        }
      );

      return updatedChoices;
    },
    [addAnswer, data.questionnaire]
  );

  const initializeRatingText = useCallback(
    (numberOfChoices: number) => {
      const initChoices = getInitialChoiceObject();
      const updatedChoices = addMultipleAnswers(initChoices, numberOfChoices);
      setAnswers(updatedChoices);
    },
    [addMultipleAnswers, getInitialChoiceObject, setAnswers]
  );

  useEffect(() => {
    const questionsData = getQuestionsDataForForm(
      data.question,
      data.questionnaire
    );
    const attributes = getAttributes(
      data.question?.type,
      data.question?.attributes
    );
    setType(data.question?.type);

    setChoiceValues(data.question);
    setAnswerValues(data.question);

    reset({
      ...questionsData,
      ...attributes,
    });
  }, [
    data.question,
    data.questionnaire,
    reset,
    setChoiceValues,
    setAnswerValues,
  ]);

  const onDragEnd = (result: any, language: any) => {
    if (!result.destination) {
      return;
    }

    const newOrder: any = reorder(
      choices[language.code],
      result.source.index,
      result.destination.index
    );

    const updatedChoices = {
      ...choices,
      [language.code]: newOrder,
    };
    setChoices(updatedChoices);
  };

  const onAnswersDragEnd = (result: any, language: any) => {
    if (!result.destination) {
      return;
    }

    const newOrder: any = reorder(
      answers[language.code],
      result.source.index,
      result.destination.index
    );

    const updatedAnswers = {
      ...answers,
      [language.code]: newOrder,
    };
    setAnswers(updatedAnswers);
  };

  const deleteAnswer = (event: any, id: number, language: any) => {
    event.preventDefault();
    const updatedAnswers = {
      ...answers,
      [language.code]: answers[language.code].filter(
        (item: any) => item._id !== id
      ),
    };
    setAnswers(updatedAnswers);
  };

  const deleteChoice = (event: any, id: number, language: any) => {
    event.preventDefault();
    const updatedChoices = {
      ...choices,
      [language.code]: choices[language.code].filter(
        (item: any) => item._id !== id
      ),
    };
    setChoices(updatedChoices);
  };

  const handleChoiceValue = (value: string, item: any, language: any) => {
    const updatedChoice = choices[language.code].map((choice: any) => {
      if (choice._id === item._id) {
        return {
          ...choice,
          content: value,
        };
      }
      return choice;
    });
    const newChoices = { ...choices };
    newChoices[language.code] = updatedChoice;
    setChoices(newChoices);
  };

  const handleAnswerValue = (value: string, item: any, language: any) => {
    const updatedAnswer = answers[language.code].map((answer: any) => {
      if (answer._id === item._id) {
        return {
          ...answer,
          content: value,
        };
      }
      return answer;
    });
    const newAnswers = { ...answers };
    newAnswers[language.code] = updatedAnswer;
    setAnswers(newAnswers);
  };

  const deleteAnswerCount = () => {
    let updatedAnswers = {};
    data.questionnaire?.supportedLanguages?.forEach((language: any) => {
      updatedAnswers = {
        ...updatedAnswers,
        [language.code]: answers[language.code].filter(
          (choice: any, index: number) => index !== numberOfChoices - 1
        ),
      };
    });

    return updatedAnswers;
  };

  const handleChoiceChange = (event: any) => {
    const value = event.target.value;

    let updatedAnswers = {};
    if (Number(value) > numberOfChoices) {
      updatedAnswers = addAnswer(answers);
    } else {
      updatedAnswers = deleteAnswerCount();
    }

    setValue('numberOfChoices', value);
    setAnswers(updatedAnswers);
  };

  const handleCustomAnswerTextOptionChange = (event: boolean) => {
    if (event) {
      const { numberOfChoices } = getValues();
      const questionsData = getQuestionsDataForForm(
        data.question,
        data.questionnaire
      );
      const languageCode = Object.keys(questionsData)[0];

      if (!questionsData?.[languageCode]?.choices?.length) {
        initializeRatingText(numberOfChoices);
      }
    }
    setValue('customAnswerText', event);
  };

  return (
    <>
      {!data.settings && (
        <div>
          <div className='inner-tabs'>
            <div>
              <div className='row m-0'>
                <div className='col col-8 pt-4'>
                  <div className='form-group'>
                    <input
                      className={`${
                        errors[data.questionnaire?.defaultLanguage.code]?.id
                          ? 'is-invalid'
                          : ''
                      } italic-input half-width-form-control `}
                      placeholder='Question label...'
                      type='text'
                      {...register(
                        `${data.questionnaire?.defaultLanguage.code}.id`,
                        {
                          required: true,
                        }
                      )}
                      disabled={data.isLoading}
                    />
                  </div>
                  <div className='form-group'>
                    <div
                      className={`bottom-border-input italic-input ${
                        errors[data.questionnaire?.defaultLanguage.code]?.label
                          ? 'is-invalid'
                          : ''
                      }`}
                    >
                      <Controller
                        control={control}
                        name={`${data.questionnaire?.defaultLanguage.code}.label`}
                        rules={{ required: true }}
                        render={({ field: { onChange, value } }) => (
                          <QuestionTextInput
                            placeholder='Start typing question text...'
                            onChange={(value: string) => {
                              const updatedValue =
                                sanitizeQuillEditorOutput(value);
                              return onChange(updatedValue);
                            }}
                            value={value}
                            disabled={data.isLoading}
                          />
                        )}
                      />
                    </div>
                    <div className='no-border-input italic-input'>
                      <Controller
                        control={control}
                        name={`${data.questionnaire?.defaultLanguage.code}.sub`}
                        rules={{ required: false }}
                        render={({ field: { onChange, value } }) => (
                          <QuestionTextInput
                            placeholder='Add description to your question'
                            onChange={(value: string) => {
                              const updatedValue =
                                sanitizeQuillEditorOutput(value);
                              return onChange(updatedValue);
                            }}
                            value={value}
                            disabled={data.isLoading}
                          />
                        )}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className='row m-0'>
                <div className='col col-8 pt-4 pb-5'>
                  <div className='form-group'>
                    <label>Questions</label>
                  </div>
                  <DragDropContext
                    onDragEnd={(result) =>
                      onDragEnd(result, data.questionnaire?.defaultLanguage)
                    }
                  >
                    <Droppable droppableId='droppable'>
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.droppableProps}
                        >
                          {choices?.[
                            data.questionnaire?.defaultLanguage?.code
                          ]?.map((item: any, index: number) => {
                            return (
                              <Draggable
                                draggableId={item?._id?.toString()}
                                index={index}
                                key={item._id}
                              >
                                {(provided) => (
                                  <div
                                    key={index}
                                    className='choices-list'
                                    ref={provided.innerRef}
                                    {...provided.dragHandleProps}
                                    {...provided.draggableProps}
                                  >
                                    <div className='choice-wrapper'>
                                      <QuestionTextInput
                                        style={{
                                          display: 'inline-block',
                                          width: '100%',
                                        }}
                                        onChange={(value: string) => {
                                          const updatedValue =
                                            sanitizeQuillEditorOutput(value);
                                          return handleChoiceValue(
                                            updatedValue,
                                            item,
                                            data.questionnaire?.defaultLanguage
                                          );
                                        }}
                                        placeholder={'New Question'}
                                        value={item.content}
                                        disabled={data.isLoading}
                                      />
                                    </div>

                                    <button
                                      style={{
                                        position: 'absolute',
                                        left: '90%',
                                      }}
                                      className='btn-delete without-bdr'
                                      onClick={(event) =>
                                        deleteChoice(
                                          event,
                                          item._id,
                                          data.questionnaire?.defaultLanguage
                                        )
                                      }
                                      disabled={data.isLoading}
                                    >
                                      <FontAwesomeIcon icon={faTrash} />
                                    </button>
                                  </div>
                                )}
                              </Draggable>
                            );
                          })}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                    <button className='btn-add-new' onClick={handleAddChoices}>
                      + Add new
                    </button>
                  </DragDropContext>
                </div>

                {(type === QuestionTypes.CHOICE_PAGE || customAnswerText) && (
                  <div className='col col-8 pt-4 pb-5'>
                    <div className='form-group'>
                      <label>Choices</label>
                    </div>
                    <DragDropContext
                      onDragEnd={(result) =>
                        onAnswersDragEnd(
                          result,
                          data.questionnaire?.defaultLanguage
                        )
                      }
                    >
                      <Droppable droppableId='droppable'>
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                          >
                            {answers?.[
                              data.questionnaire?.defaultLanguage?.code
                            ]?.map((item: any, index: number) => {
                              return (
                                <Draggable
                                  draggableId={item?._id?.toString()}
                                  index={index}
                                  key={item._id}
                                >
                                  {(provided) => (
                                    <div
                                      key={index}
                                      className='form-group choices-list'
                                      ref={provided.innerRef}
                                      {...provided.dragHandleProps}
                                      {...provided.draggableProps}
                                    >
                                      <div className='choice-wrapper'>
                                        <QuestionTextInput
                                          style={{
                                            display: 'inline-block',
                                            width: '100%',
                                          }}
                                          onChange={(value: string) => {
                                            const updatedValue =
                                              sanitizeQuillEditorOutput(value);
                                            return handleAnswerValue(
                                              updatedValue,
                                              item,
                                              data.questionnaire
                                                ?.defaultLanguage
                                            );
                                          }}
                                          placeholder={'New Answer'}
                                          onFocus={handleFocus}
                                          value={item.content}
                                          disabled={data.isLoading}
                                        />
                                      </div>
                                      <button
                                        style={{
                                          position: 'absolute',
                                          left: '90%',
                                        }}
                                        className='btn-delete without-bdr'
                                        onClick={(event) =>
                                          deleteAnswer(
                                            event,
                                            item._id,
                                            data.questionnaire?.defaultLanguage
                                          )
                                        }
                                        disabled={data.isLoading}
                                      >
                                        <FontAwesomeIcon icon={faTrash} />
                                      </button>
                                    </div>
                                  )}
                                </Draggable>
                              );
                            })}
                            {provided.placeholder}
                          </div>
                        )}
                      </Droppable>
                      <button
                        className='btn-add-new'
                        onClick={handleAddAnswers}
                      >
                        + Add new
                      </button>
                    </DragDropContext>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      {data.settings && (
        <div className='q-settings__wrapper'>
          <div className='q-settings__catLabel'>Activation</div>
          {type === QuestionTypes.CHOICE_PAGE ? (
            <Fragment>
              <div className='form-group d-flex'>
                <Controller
                  control={control}
                  name='optional'
                  rules={{ required: false }}
                  render={({ field: { onChange, value = false } }) => (
                    <ToggleSwitch
                      handleSwitch={onChange}
                      checked={value}
                      width={36}
                    />
                  )}
                />
                <label className='ml-2'>Optional</label>
              </div>
              <div className='form-group d-flex'>
                <Controller
                  control={control}
                  name='randomize'
                  rules={{ required: false }}
                  render={({ field: { onChange, value = false } }) => (
                    <ToggleSwitch
                      handleSwitch={onChange}
                      checked={value}
                      width={36}
                    />
                  )}
                />
                <label className='ml-2'>Randomize answers</label>
              </div>
            </Fragment>
          ) : (
            <Fragment>
              <div className='form-group d-flex'>
                <Controller
                  control={control}
                  name='optional'
                  rules={{ required: false }}
                  render={({ field: { onChange, value = false } }) => (
                    <ToggleSwitch
                      handleSwitch={onChange}
                      checked={value}
                      width={36}
                    />
                  )}
                />
                <label className='ml-2'>Optional</label>
              </div>
              <div className='form-group d-flex'>
                <Controller
                  control={control}
                  name='hideBackButton'
                  rules={{ required: false }}
                  render={({ field: { onChange, value = false } }) => (
                    <ToggleSwitch
                      handleSwitch={onChange}
                      checked={value}
                      width={36}
                    />
                  )}
                />
                <label className='ml-2'>Hide back button</label>
              </div>
              <div className='form-group d-flex'>
                <Controller
                  control={control}
                  name='customAnswerText'
                  rules={{ required: false }}
                  render={({ field: { onChange, value = false } }) => (
                    <ToggleSwitch
                      handleSwitch={handleCustomAnswerTextOptionChange}
                      checked={value}
                      width={36}
                    />
                  )}
                />
                <label className='ml-2'>Custom answer text</label>
              </div>
              <div className='q-settings__catLabel'>Behavior Modifiers</div>

              <div className='form-group'>
                <label className='mb-1'>Number of choices</label>
                <Controller
                  control={control}
                  name='numberOfChoices'
                  rules={{ required: true }}
                  defaultValue={2}
                  render={({ field: { value } }) => (
                    <input
                      className={errors?.numberOfChoices ? 'is-invalid' : ''}
                      onChange={(event) => {
                        handleChoiceChange(event);
                      }}
                      type='number'
                      min={2}
                      max={10}
                      value={value}
                    />
                  )}
                />
              </div>
            </Fragment>
          )}
        </div>
      )}
    </>
  );
};

export default MatrixQuestion;
